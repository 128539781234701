import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getMonthlyLeaves = async (params: TLeaveParams) => {
  console.log("search", params.search);

  const response: TServerResponse<TLeave[], unknown> = await request({
    url: apiUrls.GET_LEAVES_MONTHLY,
    method: "GET",
    params: {
      type: "monthly",
      ...params.paging,
      date: params.date,
      status: params.status,
      search: params.search,
      ...params.range,
    },
  });
  return response;
};

export const useGetMonthlyLeaves = (params: TLeaveParams) => {
  return useQuery(["leaves-monthly", params.date, params.paging.page], () =>
    getMonthlyLeaves(params)
  );
};
