import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
interface TUpdateRequest {
  status: string;
  leaveId: string;
}

const UpdateRequest = async (data: TUpdateRequest) => {
  const response: TServerResponse<unknown, unknown> = await request({
    url: apiUrls.UPDATE_REQUEST,
    method: "PUT",
    data: {
      status: data.status,
      leaveId: data.leaveId,
    },
  });
  return response;
};

export const useUpdateRequestMutation = () => {
  return useMutation(UpdateRequest);
};
