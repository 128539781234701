export enum Tenum {
  Weekend = "weekend",
  NationalHoliday = "national holiday",
  Festival = "festival",
}

export type THolidayValues = {
  date: Date;
  category: Tenum;
  name: string;
  _id?: string;
};
