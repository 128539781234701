import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { useQuery } from "react-query";

const getUpcomingHoliday = async (date: Date) => {
  const response: TServerResponse<THoliday[],unknown> = await request({
    url: apiUrls.GET_UPCOMING_HOLIDAY,
    method: "GET",
    params: {
      date: date,
    },
  });
  return response;
};

export const useGetUpcomingHoliday = (date: Date) => {
  return useQuery(["admin", "upcoming-holiday", date], () =>
    getUpcomingHoliday(date)
  );
};
