import { Card, Text } from "@mantine/core";
import React from "react";

export const NoteTile = (props: { note: string }) => {
  return (
    <Card
      mx={20}
      my={10}
      shadow="sm"
      radius="sm"
      sx={{
        borderLeft: "4px solid #ff008a",
      }}
    >
      <Text c={"black"}>{props.note}</Text>
    </Card>
  );
};
