import { useQuery } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";

const getPendingRequests = async (params: TLeaveParams) => {
  const response: TServerResponse<TLeave[], unknown> = await request({
    url: apiUrls.GET_PENDING_REQUEST,
    method: "GET",
    params: {
      type: "pending",
      ...params.paging,
      search: params.search,
      status: "pending",
      ...params.range,
    },
  });
  return response;
};

export const useGetPendingRequest = (params: TLeaveParams) => {
  return useQuery(
    ["pending-request", params],
    () => getPendingRequests(params),
    { keepPreviousData: true }
  );
};
