import { useMutation } from "react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../api-urls";
import { FileWithPath } from "@mantine/dropzone";

const BulkHoliday = async (file: FileWithPath) => {
  const formData = new FormData();

  formData.append("bulk-holiday", file);

  const response: TServerResponse<unknown,unknown> = await request({
    url: apiUrls.BULKHOLIDAY,
    method: "POST",
    data: formData,
  });
  return response;
};

export const useBulkHolidayMutation = () => {
  return useMutation(BulkHoliday);
};
