import { Box, SimpleGrid } from "@mantine/core";
import React, { useState } from "react";
import TableHeader from "./table-header/TableHeader";
import GridView from "./views/grid-view/GridView";
import TableList from "./views/table-view/TableList";
import ThemePagination from "./pagination/ThemePagination";
import ThemeLoader from "../loader/ThemeLoader";
import AttendanceCard from "../attendance-card/AttendanceCard";

const CustomTable: React.FC<TCustomTable> = ({
  headerProps,
  columns,
  data,
  loading,
  paginationProps,
  isDashboardTable,
}) => {
  const [type, setType] = useState<TTableView>("list");

  return (
    <Box style={{ position: "relative", minHeight: "100vh" }}>
      <TableHeader {...headerProps} setType={setType} view={type} />
      <Box style={{ position: "relative", minHeight: "75vh" }}>
        {type === "grid" ? (
          isDashboardTable ? (
            <SimpleGrid cols={3} spacing="xl" mt={25}>
              {data.map((attendance: TAttendanceData, i) => (
                <AttendanceCard key={"att-card" + i} data={attendance} />
              ))}
            </SimpleGrid>
          ) : (
            <SimpleGrid cols={3} spacing="xl" mt={25}>
              {data.map((student: TStudentData) => (
                <GridView data={student} key={student._id} />
              ))}
            </SimpleGrid>
          )
        ) : (
          <TableList data={data} columns={columns} />
        )}
        <ThemeLoader loading={loading ?? false} />
      </Box>

      <Box>
        {paginationProps && (
          <ThemePagination
            setPage={paginationProps.setPage}
            totalPages={paginationProps.totalPages}
          />
        )}
      </Box>
    </Box>
  );
};

export default CustomTable;
