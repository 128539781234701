export const getDateRange = (dateStrings: string[]): { from: string; to: string } => {
    if (dateStrings.length === 0) {
      throw new Error("Date array is empty");
    }
  
    const dates = dateStrings.map((dateStr) => new Date(dateStr));
  
    dates.sort((a, b) => a.getTime() - b.getTime());
  
    const from = dates[0].toLocaleDateString();
    const to = dates[dates.length - 1].toLocaleDateString();
  
    return { from, to };
  }