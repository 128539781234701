import { useQuery } from "react-query";
import { apiUrls } from "../api-urls";
import { request } from "../../services/axios.service";

const getNote = async (date: Date, id?: string) => {

  const response: TServerResponse<TNote[],unknown> = await request({
    url: apiUrls.GET_NOTE,
    method: "GET",
    params: {
      date: date,
      userId: id,
    },
  });
  return response;
};

export const useGetNote = (date: Date, id?: string) => {
  return useQuery(["user", "get-notes", date, id], () => getNote(date, id));
};
