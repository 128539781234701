import { Flex, Tabs } from "@mantine/core";
import {
  IconSquareRoundedCheck,
  IconHourglassEmpty,
  IconList,
  IconBan,
  IconSquareRoundedX,
} from "@tabler/icons-react";
import React from "react";
import { PendingRequest } from "./components/PendingRequest";
import { AllRequest } from "./components/AllRequest";

export const LeaveManagement: React.FC = () => {
  return (
    <Flex direction="column" mih="100%" miw="100%">
      <Tabs defaultValue="all">
        <Tabs.List>
          <Tabs.Tab value="all" icon={<IconList size="0.8rem" />}>
            All
          </Tabs.Tab>
          <Tabs.Tab value="pending" icon={<IconHourglassEmpty size="0.8rem" />}>
            Pending
          </Tabs.Tab>
          <Tabs.Tab
            value="accepted"
            icon={<IconSquareRoundedCheck size="0.8rem" />}
          >
            Accepted
          </Tabs.Tab>
          <Tabs.Tab
            value="rejected"
            icon={<IconSquareRoundedX size="0.8rem" />}
          >
            Rejected
          </Tabs.Tab>

          <Tabs.Tab value="expired" icon={<IconBan size="0.8rem" />}>
            Expired
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="all" pt="xs">
          <AllRequest />
        </Tabs.Panel>

        <Tabs.Panel value="pending" pt="xs">
          <PendingRequest />
        </Tabs.Panel>

        <Tabs.Panel value="accepted" pt="xs">
          <AllRequest filter="accepted" />
        </Tabs.Panel>

        <Tabs.Panel value="rejected" pt="xs">
          <AllRequest filter="rejected" />
        </Tabs.Panel>

        <Tabs.Panel value="expired" pt="xs">
          <AllRequest filter="expired" />
        </Tabs.Panel>
      </Tabs>
    </Flex>
  );
};
