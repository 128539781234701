import React from "react";
import { Card, Text, Group, Box, Indicator, Center } from "@mantine/core";
import moment from "moment";

const DateTile = (props: { date: string; category: string; name: string }) => {
  return (
    <Card shadow="md" radius="md" padding="lg" m={5} miw={500}>
      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="md" fw={600} color="dark" tt={"capitalize"}>
          {props.category === "weekend" ? props.category : props.name}
        </Text>
        <Text ml={10} size="xs" weight={500}>
          {moment(props.date).format("DD-MMMM-YYYY")}
        </Text>
      </Group>

      <Group style={{ display: "flex", justifyContent: "space-between" }}>
        <Text fz="sm" fw={400} c="dimmed" tt={"capitalize"}>
          {props.category}
        </Text>

        <Box>
          <Center>
            <Indicator
              inline
              size={8}
              position="middle-start"
              color={new Date(props.date) > new Date() ? "yellow" : "red"}
            >
              <Text ml={10} size="xs" weight={500}>
                {new Date(props.date) > new Date() ? "Upcoming" : "Previous"}
              </Text>
            </Indicator>
          </Center>
        </Box>
      </Group>
    </Card>
  );
};

export default DateTile;
