import React from "react";
import { Group, Text, rem, Flex } from "@mantine/core";
import { DropzoneProps, Dropzone } from "@mantine/dropzone";

interface IDropZone extends DropzoneProps {
  title: string;
}

const DropZone: React.FC<IDropZone> = (props) => {
  const { title } = props;
  return (
    <>
      <Flex direction="row" justify="space-between" my={10}>
        <Text mb={15} style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>
          Upload File
        </Text>
        {/* <Button size="xs" radius="xs" onClick={downloadSampleFile}> */}
        <a
          href={`${process.env.REACT_APP_BASE_URL}/sample-file`}
          download="sample.csv"
        >
          Sample File
        </a>

        {/* </Button> */}
      </Flex>
      <Dropzone {...props}>
        <Group
          position="center"
          spacing="xl"
          style={{ minHeight: rem(220), pointerEvents: "none" }}
        >
          <div>
            <Text size="xl" inline>
              {title}
            </Text>
          </div>
        </Group>
      </Dropzone>
    </>
  );
};

export default DropZone;
