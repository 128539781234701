import React, { useCallback, useState } from "react";
import { Modal, Flex, Box, Text, Switch, Select } from "@mantine/core";
import ThemeButton from "../../../component/button/ThemeButton";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import InputField from "../../../component/form/input-field/InputField";
import { DropzoneProps, FileWithPath } from "@mantine/dropzone";
import DropZone from "../../../component/dropzone/DropZone";
import classes from "./HolidayModal.module.css";
import { useAddHolidayMutation } from "../../../hooks/holiday/mutation/addHoliday.mutation";
import { useBulkHolidayMutation } from "../../../hooks/holiday/mutation/bulkHoliday.mutation";
import {
  THolidayValues,
  Tenum,
} from "../../../form/initial-value/addHoliday.value";
import { notifications } from "@mantine/notifications";

interface IHolidayModal extends Partial<DropzoneProps> {
  assign: boolean;
  date: Date;
  opened: boolean;
  close: () => void;
  refetch: () => void;
}

const HolidayModal: React.FC<IHolidayModal> = (props) => {
  const [uploadFile, setUploadFile] = useState<boolean>(false);
  const [file, setFile] = useState<FileWithPath | null>(null);

  const { isLoading: addLoading, mutateAsync: addHoliday } =
    useAddHolidayMutation();

  const { isLoading: bulkLoading, mutateAsync: bulkHoliday } =
    useBulkHolidayMutation();

  const { getInputProps, reset, onSubmit, setFieldValue } =
    useForm<THolidayValues>({
      initialValues: {
        date: props.assign ? props.date : new Date(),
        category: Tenum.Weekend,
        name: "",
      },
    });

  const handleFormSubmit = useCallback(
    async (values: THolidayValues) => {
      const res = await addHoliday(values);
      if (res.status === "success") {
        reset();
        props.refetch();
        props.close();
        notifications.show({
          color: "green",
          message: res.message,
        });
      } else {
        return;
      }
    },
    [addHoliday, reset, props]
  );

  const handleFileSubmit = async () => {
    if (file === null) {
      notifications.show({
        color: "red",
        message: "Please upload file",
      });
      return;
    }
    const res = await bulkHoliday(file!);
    if (res.status === "success") {
      reset();
      props.close();
      notifications.show({
        color: "green",
        message: res.message,
      });
      props.refetch();
    } else {
      notifications.show({
        color: "red",
        message: res.message,
      });
    }
  };

  const toggle = (checked: boolean) => {
    setUploadFile(checked);
    setFile(null);
    reset();
  };

  return (
    <Flex direction="row" justify="end">
      <Modal
        padding={30}
        classNames={{ title: classes.modalTitle, close: classes.modalClose }}
        opened={props.opened}
        onClose={props.close}
        title={"Add Holiday"}
        centered
      >
        {!props.assign ? (
          <Switch
            checked={uploadFile}
            onChange={(e) => toggle(e.target.checked)}
            labelPosition="left"
            label="Upload File"
            color="indigo"
          />
        ) : null}
        {!uploadFile ? (
          <form onSubmit={onSubmit(handleFormSubmit)}>
            <Box className={classes.box}>
              <Text mb={15} className={classes.text}>
                Date
              </Text>
              <DateInput
                disabled={props.assign}
                clearable
                classNames={{ wrapper: classes.day }}
                {...getInputProps("date")}
              />
            </Box>

            <Select
              label="Category"
              name="category"
              placeholder="Select Category"
              onChange={(value) => {
                if (value === "national holiday") {
                  setFieldValue("category", Tenum.NationalHoliday);
                } else if (value === "festival") {
                  setFieldValue("category", Tenum.Festival);
                }
              }}
              data={[
                { value: "weekend", label: "Weekend" },
                { value: "national holiday", label: "National Holiday" },
                { value: "festival", label: "Festival" },
              ]}
            />
            <InputField
              getInputProps={getInputProps}
              label="Name"
              name="name"
            />
            <ThemeButton
              disabled={addLoading}
              loading={addLoading}
              type="submit"
              fullWidth
              mt="xl"
              className={classes.btn}
              title="Add Holiday"
            ></ThemeButton>
          </form>
        ) : (
          <form onSubmit={handleFileSubmit}>
            <DropZone
              onDrop={(file) => {
                setFile(file[0]);
              }}
              maxSize={3 * 1024 ** 2}
              accept={["text/csv"]}
              {...props}
              title={
                file === null
                  ? "Upload File Here"
                  : "File uploaded successfully."
              }
            >
              <></>
            </DropZone>
            <ThemeButton
              type="submit"
              fullWidth
              mt="xl"
              title="Add Holiday"
              loading={bulkLoading}
              disabled={bulkLoading}
            />
          </form>
        )}
      </Modal>
    </Flex>
  );
};

export default HolidayModal;
