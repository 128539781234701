import React, { useState } from "react";
import {
  Flex,
  Table,
  ScrollArea,
  createStyles,
  Box,
  ActionIcon,
  rem,
  Loader,
} from "@mantine/core";
import SearchField from "../../component/search-field/SearchField";
import { NoteTile } from "./components/NoteTile";
import {
  IconCalendar,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import { DatePickerInput } from "@mantine/dates";
import moment from "moment";
import { useNoteDetailsQuery } from "../../hooks/students/query/getNoteDetails.query";
import NoData from "../../component/no-data/NoData";
import { useGetNote } from "../../hooks/notes/getNots.query";
import { useDebouncedValue } from "@mantine/hooks";

export const Notes: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [search, setSearch] = useState<string>("");
  const [studentId, setStudentId] = useState<string | undefined>(undefined);
  const [debouncedId] = useDebouncedValue<string | undefined>(studentId, 300);
  const [date, setDate] = useState<Date>(new Date());
  const [debouncedDate] = useDebouncedValue<Date>(date, 300);
  const { data: todayNotes, isLoading: isTodayNoteLoading } = useGetNote(
    debouncedDate,
    debouncedId
  );
  const { data, isLoading } = useNoteDetailsQuery({
    paging: {
      itemPerPage: 500,
      page: 1,
    },
    date: debouncedDate,
    search: search,
  });

  const isToday = (date: Date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const handleRowClick = (id: number, studentId: string) => {
    setSelectedRow(id);
    setStudentId(studentId);
  };
  const { classes } = useStyles();

  return (
    <Flex direction="row" justify="center" align="flex-start">
      <Flex direction="column" justify="center" align="center" w="50%">
        <Box className={classes.flex}>
          <Box className={classes.people}>
            <SearchField onChangeText={setSearch} />
          </Box>
          <Flex m={18}>
            <ActionIcon
              onClick={() => setDate(moment(date).subtract(1, "day").toDate())}
              size={"lg"}
              variant="light"
              bg={"white"}
            >
              <IconChevronLeft size={16} />
            </ActionIcon>
            <DatePickerInput
              maxDate={new Date()}
              styles={{ input: { border: "none" } }}
              icon={
                <IconCalendar
                  style={{ width: rem(18), height: rem(18) }}
                  stroke={1.5}
                />
              }
              placeholder="Pick date"
              value={date}
              onChange={(val) => {
                if (val) {
                  setDate(val);
                }
              }}
            />
            <ActionIcon
              disabled={date ? isToday(date) : false}
              onClick={() => setDate(moment(date).add(1, "day").toDate())}
              size={"lg"}
              variant="light"
              bg={"white"}
            >
              <IconChevronRight size={16} />
            </ActionIcon>
          </Flex>
        </Box>
        <ScrollArea h="70vh" w="100%" scrollbarSize={8}>
          {isLoading && <Loader />}
          {!isLoading && (
            <Table
              horizontalSpacing="xl"
              verticalSpacing="md"
              highlightOnHover
              sx={{
                width: "100%",
                margin: "auto",
                border: "2px solid #ddd",
                borderRadius: "8px",
              }}
            >
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Roll No</th>
                  <th>Note Count</th>
                </tr>
              </thead>
              <tbody>
                {(data?.data as []).map((student) => (
                  <tr
                    key={student["_id"]}
                    onClick={() =>
                      handleRowClick(student["_id"], student["_id"])
                    } // Set clicked row
                    style={{
                      backgroundColor:
                        selectedRow === student["_id"] ? "#f0f0f0" : "white",
                      cursor: "pointer",
                    }}
                  >
                    <td>{student["name"]}</td>
                    <td>{student["rollNumber"]}</td>
                    <td>{student["noteLength"]}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ScrollArea>
      </Flex>
      <Flex direction="column" justify="flex-start" align="center" w="50%">
        {isTodayNoteLoading && <Loader />}
        {!isLoading &&
          !isTodayNoteLoading &&
          todayNotes?.data !== undefined &&
          (todayNotes?.data as []).length === 0 && (
            <NoData title="No Notes Today" />
          )}

        {!isLoading &&
          !isTodayNoteLoading &&
          todayNotes?.data !== undefined &&
          (todayNotes?.data as []).length !== 0 && (
            <ScrollArea h="80vh" w="100%" scrollbarSize={8}>
              {(todayNotes?.data as []).map((note) => (
                <NoteTile note={note["note"]} key={note["_id"]} />
              ))}
            </ScrollArea>
          )}
      </Flex>
    </Flex>
  );
};

const useStyles = createStyles(() => ({
  flex: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    border: "1px solid #D3D3D3",
    borderRadius: 5,
    marginBottom: 8,
    marginTop: 8,
  },

  people: {
    display: "flex",
    alignItems: "center",
  },
}));
